import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import iconLine from '../../Assets/horizontal_rule_black_48dp.svg';
import './OurSolutions.scss';

const OurSolutions = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 5000,
    // centerMode: true,
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const resizeListener = () => {
      setIsMobile(window.innerWidth <= 1024);

    };

    window.addEventListener('resize', resizeListener);

    // Remova o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    const scrollListener = () => {
      const boxes = document.querySelectorAll('.box');

      if (!isMobile){
        boxes.forEach((boxElement) => {
          if (window.scrollY > 300) {
            boxElement.classList.remove('initial-position-box');
            boxElement.classList.add('move-up');
          } else {
            boxElement.classList.add('initial-position-box');
            boxElement.classList.remove('move-up');
          }
        });
      }
    };

    window.addEventListener('scroll', scrollListener);

    // Remova o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <section className={`ourSolutions ${isMobile ? 'mobile' : ''}`}>
      <div className="ourSolutions-top">
        <h1>Nossas soluções</h1>
      </div>
      <div className="ourSolutions-middle">
        {isMobile ? (
          <Slider {...settings}>
            <div className="box">
              <div className="box-top">
                <h1>Tomada de decisões Embasada em Dados</h1>
              </div>
              <div className="line-separator"></div>
              <div className="box-description">
                <span>Capacite sua equipe a tomar decisões estratégicas fundamentadas em dados confiáveis. 
                  O BI oferece uma visão abrangente do desempenho da empresa, permitindo que lideres e tomadores de 
                  decisão identifiquem oportunidades, antecipem tendências e ajam com base em insights sólidos.</span>
              </div>
            </div>
            <div className="box">
              <div className="box-top">
                <h1>Eficiência Operacional Aprimorada</h1>
              </div>
              <div className="line-separator"></div>
              <div className="box-description">
                <span>Otimize os processos de negócios e aumente a Eficiência operacional. 
                  O BI permite a identificação de gargalos, a análise de fluxos de trabalho e a implementação
                  de melhorias contínuas. Ao compreender melhor as operações, sua equipe pode aprimorar a produtividade e 
                  reduzir custos.</span>
              </div>
            </div>
            <div className="box">
              <div className="box-top">
                <h1>Identificação de Oportunidades de Crescimento</h1>
              </div>
              <div className="line-separator"></div>
              <div className="box-description">
                <span>Descubra novas oportunidades de crescimento ao analisar dados de mercado e concorrentes. 
                  O BI permite que sua empresa se posicione de maneira estratégica, identificando nichos de mercado, 
                  segmentos de clientes em ascensão e áreas para expansão.</span>
              </div>
            </div>
          </Slider>
        ) : (
          <div className="wrapper-boxes">
            <div className="box initial-position-box">
              <div className="box-top">
                <h1>Tomada de decisões Embasada em Dados</h1>
              </div>
              <div className="line-separator"></div>
              <div className="box-description">
                <span>Capacite sua equipe a tomar decisões estratégicas fundamentadas em dados confiáveis. 
                  O BI oferece uma visão abrangente do desempenho da empresa, permitindo que lideres e tomadores de 
                  decisão identifiquem oportunidades, antecipem tendências e ajam com base em insights sólidos.</span>
              </div>
            </div>
            <div className="box initial-position-box">
              <div className="box-top">
                <h1>Eficiência Operacional Aprimorada</h1>
              </div>
              <div className="line-separator"></div>
              <div className="box-description">
                <span>Otimize os processos de negócios e aumente a eficiência operacional. 
                  O BI permite a identificação de gargalos, a análise de fluxos de trabalho e a implementação
                  de melhorias contínuas. Ao compreender melhor as operações, sua equipe pode aprimorar a produtividade e 
                  reduzir custos.</span>
              </div>
            </div>
            <div className="box initial-position-box">
              <div className="box-top">
                <h1>Identificação de Oportunidades de Crescimento</h1>
              </div>
              <div className="line-separator"></div>
              <div className="box-description">
                <span>Descubra novas oportunidades de crescimento ao analisar dados de mercado e concorrentes. 
                  O BI permite que sua empresa se posicione de maneira estratégica, identificando nichos de mercado, 
                  segmentos de clientes em ascensão e áreas para expansão.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OurSolutions;