import React, { useEffect, useState } from 'react';
import './style/reset.scss';
import './style/style.scss';
import whatAppButtonImage from './Assets/whatsapp-logo.png';
import HomeSection from './Sections/HomeSection/HomeSection';
import Solutions from './Sections/Solutions/SolutionsSection';
import AboutUs from './Sections/AboutUs/AboutUsSection';
import logo from './Assets/logo_not_desc.png';
import OurSolutions from './Sections/OurSolutions/OurSolutions';
import MenuIcon from '@mui/icons-material/Menu';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const App = () => {
  const [sectionSelected, setSectionSelected] = useState('inicio');
  const [hamburguerClicked, setHamburguerClicked] = useState(false);

  const handleButtonClick = (section: any, scrollPosition: any) => {
    setSectionSelected(section);
    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [sizeInnerWidth281px, setSizeInnerWidth281px] = useState(false)

  useEffect(() => {
    const resizeListener = () => {
      setIsMobile(window.innerWidth <= 1024);

      if (window.innerWidth < 281){
        setSizeInnerWidth281px(true)
      }
    };

    window.addEventListener('resize', resizeListener);

    // Remova o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const handleClickHamburguer = (boolean:any) => {
    setHamburguerClicked(boolean)


  };

  return (
    <main>
      <header>
        {!isMobile ? <div className="logo">
          <img src={logo} alt="" />
          <span>Tech Think Solutions</span>
        </div>: ''}
        
        {isMobile ? (
          // Renderizar o menu hamburguer aqui para telas menores ou iguais a 1024px //${hamburguerClicked ? 'fade-in' : 'fade-out'}
          <div className="mobile-menu">
            <button onClick={e => handleClickHamburguer(true)}><MenuIcon htmlColor='#217379'/></button>
            {hamburguerClicked &&
              <div className={`mobile-menu-options ${hamburguerClicked ? 'fade-in' : ''}`}> 
                <button onClick={e => handleClickHamburguer(false)}><MenuIcon htmlColor='#217379'/></button>
                <div className="sections">
                  <a href="#HomeSection"><button>Início</button></a>
                  <a href="#OurSolutions"><button >Nossas soluções</button></a>
                  <a href="#AboutUs"><button>Sobre a Tech Think Solutions</button></a>
                </div>
                <div className="social-media">
                <h1>Redes Sociais</h1>
                <div className="icons-social-media">
                  <span><LinkedInIcon color='primary' fontSize={sizeInnerWidth281px ? 'medium': 'large'} /></span>
                  <span><InstagramIcon color='secondary' fontSize={sizeInnerWidth281px ? 'medium': 'large'} /></span>
                  <span><FacebookIcon color='primary' fontSize={sizeInnerWidth281px ? 'medium': 'large'} /></span>
                  <span><AlternateEmailIcon color='success' fontSize={sizeInnerWidth281px ? 'medium': 'large'} /></span>
                </div>
              </div>
              </div>
            }

          </div>
        ) : (
          // Renderizar o menu padrão para telas maiores que 1024px
          <div className="sections">
            {/* <button onClick={() => handleButtonClick('inicio', 0)}>Inicio</button> */}
            <a href="#HomeSection"><button>Início</button></a>
            <span>|</span>
            {/* <button onClick={() => handleButtonClick('nossasSolucoes', 700)}>Nossas solucoes</button> */}
            <a href="#OurSolutions"><button >Nossas soluções</button></a>
            <span>|</span>
            {/* <button onClick={() => handleButtonClick('sobre', 2171)}>Sobre a Tech Think Solutions</button> */}
            <a href="#AboutUs"><button>Sobre a Tech Think Solutions</button></a>
          </div>
        )}
      </header>
      <section id='HomeSection'><HomeSection/></section>
      <section id='OurSolutions'><OurSolutions/></section>
      <section id='Solutions'><Solutions/></section>
      <section id='AboutUs'><AboutUs/></section>
      <div className="whatsapp-button">
        <img onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=5517992204822'} src={whatAppButtonImage} alt="Converse conosco pelo WhatsApp" />
      </div>
    </main>
  );
};

export default App;
