import React, { useEffect, useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import photoGabriel from '../../Assets/gabriel.jpeg'
import photoJhonatan from '../../Assets/jonathan.jpg'
import imageSection1 from '../../Assets/image 1.jpeg';
import imageSection2 from '../../Assets/image 2.jpeg';
import imageSection3 from '../../Assets/image 3.jpg';
import imageSection4 from '../../Assets/image 4.jpg';
import imageSection5 from '../../Assets/image 5.jpg';
import './AboutUsSection.scss'

const AboutUs = () => {
  const [contentIndex, setContentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [imageSrc, setImageSrc] = useState(imageSection1);
  const contents = [
    // {}.
    "Experiência Especializada: Com uma equipe de especialistas apaixonados por BI, entregamos soluções personalizadas que atendem às necessidades únicas do seu negócio. Nossa experiência abrange diversas indústrias, garantindo uma compreensão profunda dos desafios específicos que sua empresa enfrenta.",
    "Tecnologia de Ponta: Na Tech Think Solutions, adotamos as últimas tecnologias em BI para fornecer ferramentas poderosas e intuitivas. De análises avançadas a dashboards interativos, capacitamos sua equipe a extrair o máximo valor dos seus dados.",
    "Soluções Customizadas: Reconhecemos que cada empresa é única. Nossas soluções são totalmente personalizadas para se alinharem perfeitamente aos seus objetivos de negócios. Trabalhamos em parceria para criar uma estratégia de BI que impulsione o crescimento e a eficiência operacional.",
    "Compromisso com a Inovação: Na Tech Think Solutions, abraçamos a inovação como princípio fundamental. Estamos constantemente evoluindo nossas soluções para garantir que sua empresa esteja à frente da concorrência em um ambiente de negócios em constante transformação.",
    "Suporte Excepcional: Nossa dedicação não termina na implementação. Oferecemos suporte contínuo para garantir que você obtenha o máximo valor de nossas soluções. Sua satisfação é nossa prioridade.",
    "Transforme seus dados em ação com a Tech Think Solutions. Entre em contato conosco hoje para uma consulta personalizada e descubra como podemos impulsionar o sucesso do seu negócio por meio da inteligência de dados!"
  ];  

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setContentIndex((prevIndex) => (prevIndex + 1) % contents.length);
        setFadeIn(true);
      }, 700);
    }, 7000);

    return () => clearInterval(interval);
  }, [contents.length]);

  useEffect(() => {
    updateImageSrc();
  }, [contentIndex]);

  const updateImageSrc = () => {
    const newIndex = (contentIndex + 1) % contents.length;

    switch (newIndex) {
      case 0:
        setImageSrc(imageSection1);
        break;
      case 1:
        setImageSrc(imageSection2);
        break;
      case 2:
        setImageSrc(imageSection3);
        break;
      case 3:
        setImageSrc(imageSection4);
        break;
      case 4:
        setImageSrc(imageSection5);
        break;
      default:
        setImageSrc(imageSection1);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const resizeListener = () => {
      setIsMobile(window.innerWidth <= 1024);

    };

    window.addEventListener('resize', resizeListener);

    // Remova o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <section className='aboutUs'>
      <div className="section-aboutUs">
        <div className="text-section">
          <h1>Por que escolher a Tech Think Solutions?</h1>

          <div className={`p-section ${fadeIn ? 'fade-in' : 'fade-out'}`}>
            <span>{contents[contentIndex]}</span>
          </div>

        </div>

        <div className="image-section">
          <div className="image">
            <img src={imageSrc} alt="" />
          </div>
        </div>
      </div>

      {!isMobile ?
        <div className="footer-aboutUs">
          <div className="social-media">
            <h1>Redes Sociais</h1>
            <div className="icons-social-media">
              <span><LinkedInIcon color='primary' fontSize='large' /></span>
              <span><InstagramIcon color='secondary' fontSize='large' /></span>
              <span><FacebookIcon color='primary' fontSize='large' /></span>
              <span><AlternateEmailIcon color='success' fontSize='large' /></span>
            </div>
          </div>
          <div className="section-us">
            <div className="photos">
              <div className="card-photos">
                <div className="image">
                  <img src={photoGabriel} alt="" />
                </div>
                <div className="info">
                  <h1>Gabriel</h1>
                  <div className="line-separator"></div>
                  <span>CEO</span>
                </div>
              </div>
              <div className="card-photos">
                <div className="image">
                  <img src={photoJhonatan} alt="" />
                </div>
                <div className="info">
                  <h1>Jonathan</h1>
                  <div className="line-separator"></div>
                  <span>CMO</span>
                </div>
              </div>
            </div>
          </div>
        </div> : 
        // Mobile
        <div className="footer-aboutUs">
          <div className="section-us">
            <div className="photos">
              <div className="card-photos">
                <div className="image">
                  <img src={photoGabriel} alt="" />
                </div>
                <div className="info">
                  <h1>Gabriel</h1>
                  <div className="line-separator"></div>
                  <span>CEO</span>
                </div>
              </div>
              <div className="card-photos">
                <div className="image">
                  <img src={photoJhonatan} alt="" />
                </div>
                <div className="info">
                  <h1>Jonathan</h1>
                  <div className="line-separator"></div>
                  <span>CMO</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="social-media">
            <h1>Redes Sociais</h1>
            <div className="icons-social-media">
              <span><LinkedInIcon color='primary' fontSize='large' /></span>
              <span><InstagramIcon color='secondary' fontSize='large' /></span>
              <span><FacebookIcon color='primary' fontSize='large' /></span>
              <span><AlternateEmailIcon color='success' fontSize='large' /></span>
            </div>
          </div> */}
        </div>}
    </section>
  )
}
export default AboutUs;
