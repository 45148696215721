import React from 'react';
import iconDownArrow from '../../Assets/keyboard_arrow_down_black_24dp.svg'
import './HomeSection.scss';

const HomeSection = () => {
  const videoUrl = 'http://localhost:3000/src/Assets/videos/homeVideo.mp4'; // Caminho relativo ao diretório 'public'

  return (
    <section className='home'>

      <div className="section-home">
        <div className="left-home">
          
        </div>
        <div className="right-home">
          <div className="wrapper-call">
            <h1>Temos as soluções para o seu negócio e carreira</h1>
          </div>
        </div>
      </div>

      <div className="footer-home">
        <img src={iconDownArrow} alt="" />
      </div>
      
    </section>
  );
};

export default HomeSection;
