import React from 'react';
import './SolutionsSection.scss'
import ReactPlayer from 'react-player';

const videoUrl = 'static/media/videos/homeVideo1.mp4'

const Solutions = () => {
  return(
    <section className='solutions'>
      <div className="left-solutions">
        <video className='video-wrapper' autoPlay muted loop>
          <source src={videoUrl} type='video/mp4'/>
          tetes
        </video>
      </div>

      <div className="right-solutions">
        <h1>Business Intelligence, o que é?</h1>
        <span>O Business Intelligence (BI) combina análise empresarial, mineração
              e visualização de dados, de modo a ajudar as organizações na tomada 
              de decisões estratégicas para o negocio.</span>
      </div>

    </section>
  )
}
export default Solutions;
